import React, { useEffect, useState } from 'react'

import NavBar from '../components/navbar/NavBar'
import StarParalax from '../components/starparalax/StarParalax'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import SearchBar from '../components/searchbar/SearchBar'
import ArtistAvatar from '../components/artistavatar/ArtistAvatar'

import Slider from "react-slick";

import ExclusiveDrops from '../components/exclusivedrops/ExclusiveDrops'
import HotAuction from '../components/hotauction/HotAuction'
import FilterButton from '../components/filterbutton/FilterButton'
import ExploreImage from '../components/exploreimage/ExploreImage'
import BuyImage from '../assets/images/buy.png'
import MainLogo from '../assets/images/main-logo.png'
import SubLogo from '../assets/images/sub-logo.png'
import PizzaRed from '../assets/images/pizzab-red.png'

const Home = () => {

    return (
        <>
            <div className="landing-page-main">
                <NavBar />
                <div className="main-hero">
                    <div className="container">
                        <div className="buy-container">
                            <div id="animated-buy" class="animated bounce">
                                <a href="https://pancakeswap.finance/swap?outputCurrency=0x241b8e70e25495bb347b3e267ad2e27dcc3b2506" target="_blank"><img src={BuyImage} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sub-logo">
                        <img src={SubLogo} alt="" />
                    </div>
                    <div className="main-logo">
                        <div>
                            <img src={MainLogo} alt="" />
                        </div>
                        <div className='pizza-red-logo'>
                            <img src={PizzaRed} alt="" />
                        </div>
                    </div>
                </div>

                <Footer />
                {/* <ScrollTop /> */}
            </div>

        </>
    )
}

export default Home
